import React from 'react'
import Date from "../utils/date"
import Button from 'components/button'
import Link from 'components/link'

export default ({ post, index }) => {
    const background = post.featuredImage ? post.featuredImage.mediaItemUrl : ''

    const category = post.categories && post.categories.nodes.length ? post.categories.nodes[0] : null 

    return (
        <li key={index} className="blog-grid-post" >
            <div className="blog-grid-text">
                <div className="blog-grid-details">
                    { category ?
                        <>
                            <div 
                                data-colour={category.slug}
                                dangerouslySetInnerHTML={{ __html: category.name }}
                            />
                            <div>
                                <Date date={post.date} />
                            </div>
                        </>
                    : null }
                </div>
                <Link to={post.link}>
                    <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
                </Link>
                <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                <Button url={post.link } />
            </div>
            <div className="blog-grid-image">
                <div style={{ backgroundImage: `url(${background})` }}>
                    <Link to={post.link } />
                </div>
            </div>
        </li>
    )
}
